/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalloutInfoResponse } from '../models/CalloutInfoResponse';
import type { MeetingCalloutSettingsResponse } from '../models/MeetingCalloutSettingsResponse';
import type { SetCalloutSettingsRequest } from '../models/SetCalloutSettingsRequest';
import { request as __request } from '../core/request';

export class CallService {

    /**
     * @returns CalloutInfoResponse
     * @throws ApiError
     */
    public static async getCalloutInfo(): Promise<CalloutInfoResponse> {
        const result = await __request({
            method: 'GET',
            path: `/calls/callout/info`,
        });
        return result.body;
    }

    /**
     * @returns CalloutInfoResponse
     * @throws ApiError
     */
    public static async startCallout(): Promise<CalloutInfoResponse> {
      const result = await __request({
        method: 'POST',
        path: '/calls/callout/start/'
      });
      return result.body;
    }

        /**
     * @returns CalloutInfoResponse
     * @throws ApiError
     */
      public static async startCalloutExternal(): Promise<CalloutInfoResponse> {
        const result = await __request({
          method: 'POST',
          path: '/calls/callout/start/external'
        });
        return result.body;
      }

    /**
     * @param meetingId
     * @returns MeetingCalloutSettingsResponse
     * @throws ApiError
     */
    public static async getCalloutSettings(
        meetingId: number,
    ): Promise<MeetingCalloutSettingsResponse> {
        const result = await __request({
            method: 'GET',
            path: `/calls/${meetingId}/callout/settings`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns MeetingCalloutSettingsResponse
     * @throws ApiError
     */
    public static async setCalloutSettings(
        meetingId: number,
        requestBody: SetCalloutSettingsRequest,
    ): Promise<MeetingCalloutSettingsResponse> {
        const result = await __request({
            method: 'POST',
            path: `/calls/${meetingId}/callout/settings`,
            body: requestBody,
        });
        return result.body;
    }

}